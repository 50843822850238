
import React from 'react';
import { DayOfWork } from '../../../Services/useSowPlanificationView';
import {  CartesianGrid, Tooltip, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis, Label } from 'recharts';


interface ResourceGraphProps{
  graphicData: DayOfWork[] | undefined,
  greenTons: boolean
}

const ResourceGraph = React.memo<ResourceGraphProps>(({
  graphicData,
  greenTons
}) => {  
  console.log(graphicData)

  
  return (
    <div className='box_filter'>
      <div style={{display:'flex'}}>
          <p>Gráfico de{greenTons ? ' toneladas verdes' :' recursos'}</p>
          {/* <Tooltip
              style={{cursor:'pointer'}}
              title='Selecciona los datos que deseas visualizar en la tabla'
          >
              <ExclamationCircleOutlined 
                  style={{
                      color: '#fff384c5',
                      marginLeft: '10px'
                  }}
              />
          </Tooltip> */}
      </div>  
      <div className="sentinelGraph_chartContainers">
        <ResponsiveContainer width="80%" height="70%">
          <ComposedChart
            width={1000}
            height={450}
            data={graphicData}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            
            <Tooltip/>
            <CartesianGrid stroke="#4b4b4b" strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              angle={-45}
              tick={{ fontSize: 12 }}
              tickMargin={15}
              textAnchor="end"
            />
            <YAxis>
              <Label
                value="Cantidad de recursos"
                position="left"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Line
                name= {greenTons ? 'Toneladas verde' :'Recursos'}
                legendType="plainline"
                type="monotone"
                dataKey={greenTons ? 'green_tons' :'resources'}
                stroke= {greenTons ? '#00FF00' : '#ffe600'}
            />

            <Legend/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>             
    </div>
  );
})

export default ResourceGraph;
  